<template lang="html">
  <div class="component-wrapper" v-if="this.popular">
      <div class="d-flex flex-wrap justify-content-center justify-content-md-start">
          <div class="mr-2 change-popular-view-button" @click="setPopularView('', '')">
              <p>All</p>
          </div>
          <div class="change-popular-view-button" @click="setPopularView('comedy_score__gte', 'comedy')">
              <p>Comedy</p>
          </div>
          <div class="change-popular-view-button" @click="setPopularView('crime_score__gte', 'crime')">
              <p>Crime</p>
          </div>
          <div class="change-popular-view-button" @click="setPopularView('fantasy_score__gte', 'fantasy')">
              <p>Fantasy</p>
          </div>
          <div class="change-popular-view-button" @click="setPopularView('folklore_score__gte', 'folklore')">
              <p>Folklore</p>
          </div>
          <div class="change-popular-view-button" @click="setPopularView('horror_score__gte', 'horror')">
              <p>Horror</p>
          </div>
          <div class="change-popular-view-button" @click="setPopularView('mystery_score__gte', 'mystery')">
              <p>Mystery</p>
          </div>
          <div class="change-popular-view-button" @click="setPopularView('sci_fi_score__gte', 'sci-fi')">
              <p>Sci-fi</p>
          </div>
          <div class="change-popular-view-button" @click="setPopularView('thriller_score__gte', 'thriller')">
              <p>Thriller</p>
          </div>
      </div>
      <SearchResults :header="`Popular ${this.genre} podcasts`"
                     :searchResultsData="this.popular">
      </SearchResults>
  </div>
</template>

<script>
import SearchResults from '@/components/search_results/SearchResults.vue'
export default {
    name: 'PopularView',
    title: 'Popular',
    components: {
        SearchResults
    },
    data() {
        return {
            popular: null,
            whichPopular: '',
            genre: ''
        }
    },
    mounted() {
        this.getPopular()
    },
    watch: {
        whichPopular() {
            this.getPopular()
        }
    },
    methods: {
        getPopular() {
            this.$http.get(`${this.$store.state.endpoints.baseUrl}small-podcasts/?${this.whichPopular}=20&limit=20`).then((response) => {
                this.popular = response.data
            })
        },
        setPopularView(view, genre) {
            this.whichPopular = view
            this.genre = genre
        }
    },
}
</script>

<style lang="css">
.change-popular-view-button {
    margin-right: 0.6em;
    background: var(--lightBackground);
    width: 6em;
    border-radius: 0.4em;
    padding: 0.4em;
    display: flex;
    justify-content: center;
    box-shadow: var(--globalButtonShadow);
    margin-bottom: 1em;
    cursor: pointer;
    color: white;
}
</style>
